<template>
	<section class="domain_content">
		<!-- About Us -->
		<div class="sub-page">
			<h1>About Us</h1>
			<p>
				Thanks for visiting our site!
			</p>
			<p>
				GB WhatsApp is a professional technology platform dedicated to delivering engaging and valuable content. Our mission is to provide you with the latest news in mobile applications. 
			</p>
			<p>We'll keep updating blogs related to tech news and GB WhatsApp app. Hope all things we do can help you even a bit.</p>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'About Us'
const description = 'Our site provides the latest version of GB WhatsApp apk for visitors. This page introduces more things about the site.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://gbapks.org.in/about-us/"
            }]
	},
}
</script>
  